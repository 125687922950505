import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { TeamGroup } from "@store/team/team-types";
import { teamGroupAdapter } from "@store/team/team-slice";

/** Return the list of team groups. */
export const teamGroupsSelector: (state: RootState) => TeamGroup[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return teamGroupAdapter.getSelectors().selectAll(state.team);
    }
  );
