import { createAsyncThunk } from "@reduxjs/toolkit";
import { TeamGroup } from "@store/team/team-types";

const mockedTeamGroups: TeamGroup[] = Array.from(
  { length: 70 },
  (_, index) => ({
    id: (index + 1).toString(),
    name: `Group ${index + 1}`,
    sampleMembers: [
      {
        userResponse: {
          identity: (index + 1).toString(),
          lastName: `LastName${index + 1}`,
          firstName: `FirstName${index + 1}`,
          email: `user${index + 1}@example.com`,
        },
        createdAt: "2023-09-17T12:00:00Z",
      },
    ],
  })
);

export const fetchTeam = createAsyncThunk<TeamGroup[]>(
  "teams/fetchTeam",
  async () => {
    return new Promise<TeamGroup[]>((resolve) =>
      setTimeout(() => resolve(mockedTeamGroups), 0)
    );
  }
);
