import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { withEllipsis } from "@styles/common-styles";
import { GroupTypes } from "@custom-types/group-types";
import { TeamGroupHeaders } from "@components/table/team-group/team-groups-table-utils";

interface Props {
  // TODO: Extract it in separate file or access in in the component: https://faro01.atlassian.net/browse/ST-1348
  options: {
    /** Whether the current screen is large or larger */
    isScreenLgOrLarger: boolean;

    /** Whether the current screen is medium */
    isMedium: boolean;
  };
}

/** Minimum column width in px */
const COLS_MIN_WIDTH_IN_PX = 100;

/** Return all the possible columns for Teams table */
export function getTeamGroupsColumns({
  options,
}: Props): Record<TeamGroupHeaders, GridColDef> {
  const flexSetting = options.isScreenLgOrLarger ? 1 : undefined;

  return {
    [TeamGroupHeaders.name]: {
      field: TeamGroupHeaders.name,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: 380,
      renderCell: (params: GridRenderCellParams<{ entity: GroupTypes }>) => {
        return (
          <SphereTooltip
            title={<var>{params.row.entity.name}</var>}
            boxProps={{
              sx: {
                ...withEllipsis,
                fontWeight: "bold",
              },
            }}
          >
            <var>{params.row.entity.name}</var>
          </SphereTooltip>
        );
      },
    },
  };
}
