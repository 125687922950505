import { GroupsEmptyPage } from "@components/common/empty-page/groups-empty-page";
import { TeamGroupTable } from "@components/table/team-group/team-group-table";
import { TeamGroupDetails } from "@pages/members/team-groups/team-groups-details";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import { teamGroupsSelector } from "@store/team/team-selector";
import { fetchTeam } from "@store/team/team-thunk";
import { useEffect, useMemo } from "react";
import { TeamGroupHeaders } from "@components/table/team-group/team-groups-table-utils";

/**
 * The component that will render the kpi details and the table for the user groups
 */
export function TeamGroups(): JSX.Element {
  const dispatch = useAppDispatch();
  const mockedTeamGroups = useAppSelector(teamGroupsSelector);
  const teamStatus = useAppSelector((state) => state.team.status);

  useEffect(() => {
    dispatch(fetchTeam());
  }, [dispatch]);

  const shouldShowEmptyPage = useMemo(() => {
    return (
      teamStatus === FetchingStatus.succeeded && mockedTeamGroups.length === 0
    );
  }, [teamStatus, mockedTeamGroups]);

  return shouldShowEmptyPage ? (
    <GroupsEmptyPage />
  ) : (
    <>
      <TeamGroupDetails />
      <TeamGroupTable
        teamGroups={mockedTeamGroups}
        isLoading={teamStatus === FetchingStatus.pending}
        requiredColumns={[TeamGroupHeaders.name]}
      />
    </>
  );
}
